import React from 'react';

import TravelTipsHeader from './TravelTipsHeaderComponent';

function MoneySave(props) {
    return (
        <React.Fragment>
            <TravelTipsHeader image="https://live.staticflickr.com/65535/50380749847_b19af4883e_o.jpg"
                thumb="https://live.staticflickr.com/65535/50380749847_e6470db9c1_t.jpg"
                title="Money Saving" subtitle="Travel Tips" />

            <div className="container">
                <div className="city-text">
                    <div className="col-12 col-lg-10 ms-auto me-auto" >
                        <p style={{ marginTop: '40px', marginBottom: '40px' }}>
                        Traveling can be expensive, and when on the road everything becomes an expense. If traveling for many weeks, all the pennies start to add up and suddently your are exceeding your budget.
                        Thus, where and how you spend your money is an important subject, and there are a lot of simple tools and tricks that can help you significantly bring down the costs.
                        </p>
                    </div>

                    <div className="city-topic">
                        <div className="col-12 col-lg-10 ms-auto me-auto">
                            <h1>Revolut</h1>
                            <p>
                            Revolut is a financial technology company, and works like a normal bank.
                            You get a prepaid debit card, which can be used to withdrawal money from ATM's at home and abroad, make online payments and everything you should expect from a normal debit card.
                            <br/><br/>
                            The big deal with Revolut is that there are no <b>extra fees</b> when paying something with an foreign currency.
                            Revolut <b>does not charge you</b> any transition fees or conversion taxes, and so if you only have Dollars on your account and are paying something in Euros, Revolut will make the conversion using the current conversion rates and there will be no hidden costs.
                            Only at weekends does Revolut charge a <b>0.5% fee</b> for conversion taxes, but if you convert your money during the week you will have no problem.
                            <br/><br/>
                            Thrust us when we say that this simple card can save you big amounts of money on the long run, and as such we couldn't recommend it more.
                            As the cherry on top of the cake, requesting a Revolut card is 100% free, and there are no monthly or yearly fees to keep it working.
                            This is just 100% saving money, with no drawbacks whatsoever.
                            </p>
                        </div>
                    </div>

                    <div className="city-topic">
                        <div className="col-12 col-lg-10 ms-auto me-auto">
                            <h1>Booking</h1>
                            <p>
                            Our favourite place to book accommodations worldwide.
                            The sheer <b>wide range of offers</b>, from budget hostel to luxury resorts, assures you will find the accommodation you are looking for on your next journey.
                            There are free cancellation policies, accommodations with breakfast included and other perks on many stays. This is a fantastic search engine.
                            </p>
                        </div>
                    </div>

                    <div className="city-topic">
                        <div className="col-12 col-lg-10 ms-auto me-auto">
                            <h1>The Fork</h1>
                            <p>
                            Are you traveling around Europe? And are you looking for a cheap place to eat? Then give The Fork a try.
                            <br/><br/>
                            This is a great app to try <b>new restaurants</b> and get some <b>discounts</b> in the process.
                            The Fork is available in many countries around Europe, and it allows you to make a reservation for a table on a restaurant, and this usually comes with a discount, ranging from 15% to 30%.
                            Take a look at the app to understand more how it works, the interface is easy very user-friendly.
                            </p>
                        </div>
                    </div>

                    <div className="city-topic">
                        <div className="col-12 col-lg-10 ms-auto me-auto">
                            <h1>Uber - Grab - Bolt - FreeNow</h1>
                            <p>
                            They are all ride-sharing apps, and each of these is present on many different countries.
                            When traveling abroad always make sure which app is most popular there.
                            <br/><br/>
                            Using these apps is usually cheaper than calling a taxi, and you can see the price beforehand, so there will never be rip-offs like what can happen on normal taxis.
                            </p>
                        </div>
                    </div>

                    <div className="city-topic">
                        <div className="col-12 col-lg-10 ms-auto me-auto">
                            <h1>GigSky</h1>
                            <p>
                            If you have an eSIM compatible phone, with this app you can conveniently purchase data plans for every country you are traveling to before your departure.
                            No more need for dodgy Wi-Fi or physical SIMs!
                            </p>
                        </div>
                    </div>

                    <div className="city-topic">
                        <div className="col-12 col-lg-10 ms-auto me-auto">
                            <h1>TripAdvisor</h1>
                            <p>
                            It is a powerful app to help on your travel planning, but it's also important to know how to use it.
                            There are a lot of fake reviews and ads that you need to ignore. The travel forums are great and allow you to get in contact with other travelers, and check reviews from tours and restaurants too.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default MoneySave;
