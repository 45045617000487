import React from 'react';
import MoneySave from '../../components/travelTips/MoneySavingComponent';
import Footer from '../../components/FooterComponent';

import MainPageMetadata from '../../metadata/MainPageMetadata';

function MoneySavingPage(props) {
    return (
        <div>
            <MainPageMetadata pathname={ props.location.pathname } />
            <MoneySave />
            <Footer />
        </div>
    )
}

export default MoneySavingPage;
